import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-section-content',
    templateUrl: './section-content.component.html',
    styleUrls: ['./section-content.component.scss']
})

export class SectionContentComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
