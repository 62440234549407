<div class="footer-container">
    <div class="logo-container">
        <div>
            <img i18n-alt src='https://media.allergan.com/allerganaesthetics/dreamforequity/skinbetter%20logo.png'
                alt='Skinbetter Science' />
        </div>
        <div>
            <img i18n-alt class="abbvie-logo" src='https://media.allergan.com/allerganaesthetics/dreamforequity/abbvie-logo-with-tagline.png'
                alt='Allergan Aesthetics logo' />
        </div>
    </div>
    <div class="info-container">
        <div class="stacked">
            <div class="inline">
                <a class="footer-link" href="https://www.abbvie.com/accessibility-statement.html" target="_blank">Accessibility Statement</a>
                <a class="footer-link" href="https://www.abbvie.com/contactus.html" target="_blank">Contact Us</a>
                <a class="footer-link" href="https://www.abbvie.com/terms-of-use.html" target="_blank">Terms of Use</a>
                <a class="footer-link" href="https://privacy.abbvie/" target="_blank">Privacy Notice</a>
                <a class="footer-link" href="https://abbv.ie/PrivacyConsumerHealth" target="_blank">Consumer Health Data Privacy Notice</a>
                <button
                    class="footer-button"
                    id="ot-sdk-btn" class="ot-sdk-show-settings cookie-settings">Cookie Settings</button>
                <a href="http://#privacy-choices" target=_blank data-alert-key=default></a>
            </div>
            <span i18n class="footer-tag">© {{getYear()}} AbbVie. All rights reserved. <br> UNB152356-v4 09/22</span>
        </div>

    </div>
</div>