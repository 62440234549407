import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-aad-content',
    templateUrl: './AADContent.component.html',
    styleUrls: ['./AADContent.component.scss']
})

export class AADContentComponent implements OnInit {
    constructor() { }
    ngOnInit(): void { }
}