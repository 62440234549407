import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-about-dream-section',
    templateUrl: './about-dream.component.html',
    styleUrls: ['./about-dream.component.scss']
})

export class AboutDreamSection implements OnInit {
    constructor() { }
    ngOnInit(): void { }
}