<section>
    <app-section-content>
        <div class="content">
            <span>
                *Data from a July 2021 online survey with 3,587 participants. Individuals pictured on website were not
                included in the
                survey.
            </span>
            <div class="btn-container">
                <a href="https://media.allergan.com/allerganaesthetics/dreamforequity/reports/UNB157406-DREAM%20Forces%20of%20Beauty%20Report.pdf"
                    target="_blank">
                    <app-btn [style]="buttonStyles.primarySmall">VIEW REPORT</app-btn>
                </a>
            </div>
        </div>
    </app-section-content>
</section>