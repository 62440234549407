import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-aad-hero-image',
    templateUrl: './AADHeroImage.component.html',
    styleUrls: ['./AADHeroImage.component.scss']
})

export class AADHeroImage implements OnInit {
    constructor() { }
    ngOnInit(): void { }
}