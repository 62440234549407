<div class="press-release-container">
  <div class="press-release-header">
    <a href="/">
      <img class="headline-image" src='https://media.allergan.com/allerganaesthetics/dreamforequity/DREAM%20logo.png' />
    </a>
  </div>
  <div class="press-release-content">
    <span class="date">September 14, 2020</span>
    <h1 class="title">Allergan Aesthetics and Skinbetter Science Announce The DREAM Initiative™ To Advance Health Equity
      and Diversity in
      Aesthetics</h1>
    <p>
      IRVINE, Calif. and PHOENIX, Sept. 14, 2020 /PRNewswire/ — Today Allergan Aesthetics, an AbbVie company (NYSE:
      ABBV), and
      Skinbetter Science® announced the launch of a new long-term, educational initiative – DREAM: Driving Racial Equity
      in
      Aesthetic Medicine™. The DREAM Initiative™ is committed to furthering the principles of racial and ethnic
      diversity,
      inclusion, respect, and understanding in the fields of dermatology and plastic surgery.
    </p>
    <p>
      “As leaders in aesthetics,
      Allergan Aesthetics and Skinbetter Science are like-minded in our pursuit of advancing equality and diversity in
      the
      aesthetics market and believe it is our responsibility to create meaningful, positive change within our own
      companies,
      and with our customers,” stated Carrie Strom, Senior Vice President, AbbVie and President, Global Allergan
      Aesthetics.
      “We are inaugurating the DREAM Initiative™ with three important projects, which will immediately expand available
      resources to enhance the care of all patients, as well as to better diagnose and treat dermatologic conditions
      across
      the full spectrum of skin types and patients of color.”
    </p>
    <p>
      Case studies and data relevant to darker skin tones are
      grossly
      underrepresented in current educational materials and textbooks which can lead to under- or misdiagnoses for black
      and
      brown patients. Allergan Aesthetics and Skinbetter Science endeavor to start to bridge this gap in dermatology and
      plastic surgery with The National Racial Equity Medical Residency Curriculum. This program is designed to assist
      students, faculty, and staff in dermatology and plastic surgery residency programs in identifying and addressing
      the
      risk
      of implicit bias in academic and clinical settings. This curriculum is being piloted at a leading university, with
      subsequent nationwide rollout planned thereafter. The DREAM Initiative™ is also sponsoring the development of The
      Spectrum of Dermatologic Disease, a comprehensive textbook photo atlas, which will present the characteristics of
      skin
      conditions across the full range of racial and ethnic skin types. The Spectrum of Dermatologic Disease, upon
      publication, will be disseminated to medical residents, faculty, and program libraries, and thereafter be available
      as a
      resource for clinical practitioners worldwide.
    </p>
    <p>
      The DREAM Initiative™ plans to convene Forces of Beauty™ Summits to
      help
      identify different perceptions of beauty among racial and ethnic groups, so that media, aesthetic providers, and
      corporations can better understand and meet the aesthetic needs of all patients.
    </p>
    <p>
      “Inspired by the nationwide
      peaceful
      protests that have galvanized our communities in calling for racial equity in all sectors of society, Skinbetter
      Science
      and Allergan Aesthetics wanted to identify ways in which we could leverage our collective experience and knowledge
      to
      make a tangible contribution to improving health equity,” stated Jonah Shacknai, Executive Chairman of Skinbetter
      Science. “Helping to make aesthetics and dermatology more inclusive and fair, and enabling physicians and
      healthcare
      providers to bring that to life, is an important place to start.”
    </p>
    <span class="content-title">About Skinbetter Science</span>
    <p>
      The Skinbetter Science
      team
      of aesthetic experts has a profound understanding of skin aging and what it takes to help defy the effects of
      time.
      Tapping into a rich dermatological heritage, the team at Skinbetter Science set out to create a new paradigm in
      clinical
      skincare. Cutting-edge, data-driven science is the principal driving force behind all our unique formulations.
      Skinbetter Science award-winning products are available exclusively through leading dermatology, plastic
      surgery, and medical aesthetics practices nationwide. For more information, visit us at Skinbetter.com or follow us on
      Facebook,
      Instagram, and LinkedIn.
    </p>
    <span class="content-title">About Allergan Aesthetics</span>
    <p>
      At Allergan Aesthetics, an AbbVie company, we develop,
      manufacture,
      and market a portfolio of leading aesthetics brands and products. Our aesthetics portfolio includes facial
      injectables,
      body contouring, plastics, skincare, and more. Our goal is to consistently provide our customers with innovation,
      education, exceptional service, and a commitment to excellence, all with a personal touch. 
    </p>
    <span class="content-title">About AbbVie</span>
    <p>
      AbbVie’s mission is to discover and
      deliver innovative medicines that solve serious health issues today and address the medical challenges of
      tomorrow.
      We
      strive to have a remarkable impact on people’s lives across several key therapeutic areas: immunology, oncology,
      neuroscience, eye care, virology, women’s health, and gastroenterology, in addition to products and services across
      its
      Allergan Aesthetics portfolio. For more information about AbbVie, please visit us at www.abbvie.com. Follow
      @abbvie
      on
      Twitter, Facebook, Instagram, YouTube, and LinkedIn.
    </p>
    <div class="btn-container">
      <app-btn class="back-btn" [style]="buttonStyles.primarySmall" routerLink="/"><i class="fas fa-arrow-left"></i> &nbsp;
        BACK TO THE DREAM INITIATIVE™</app-btn>
    </div>
  </div>
</div>