<div class="content-container">
    <div class="logo-container">
        <img class='logo' src='https://media.allergan.com/allerganaesthetics/dreamforequity/DREAM-final-logo-black-tagline-Registration.png' />
    </div>
    <app-register-form></app-register-form>
<div class="content">
    <h1 i18n>Forces of Beauty™</h1>
    <h2 i18n>A Lens Into Inclusivity in Aesthetics</h2>
    <p i18n>
        Industry leaders Allergan Aesthetics and skin<strong>better</strong> science have joined forces in pursuit of advancing
        equity and
        diversity in the aesthetics market. Together, through DREAM®, Driving Racial Equity in Aesthetic
        Medicine,
        we are
        focused on education, and committed to furthering racial and ethnic diversity, inclusion, tolerance, and
        understanding in
        the fields of dermatology and plastic surgery.
    </p>
    <p i18n>
        The Forces of Beauty™ report is the result of qualitative and
        quantitative research conducted with over 4,000 women, aged 21-65, across numerous ethnicities and
        geographic locations.
        Their voices provide a deeper understanding of what inclusive and representative beauty looks like
        today. This
        research will provide aesthetic providers, media, and other industry leaders insights on the unique
        dynamic
        between
        beauty and racial identity for women within the United States.
    </p>
    <p i18n>
        With this research, we hope to provide a much-needed look
        into the world of real aesthetics patients in all of their beautiful diversity. Our goal was to showcase
        how
        beauty
        impacts women’s lives and the way in which women identify or not with the images they see in media and
        branding. The
        results provide insights that can help us all take one small step toward making the world of aesthetics
        more
        inclusive,
        and enable physicians and healthcare providers to support the aesthetic needs of ALL patients.
    </p>
</div>
</div>