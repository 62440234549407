import {
  faLongArrowRight,
  faAngleDown,
  faAngleUp,
  faTimes,
  faChevronRight,
  faSpinnerThird,
  faChevronLeft,
} from '@fortawesome/pro-light-svg-icons';

export const iconTypes = {
  arrowRight: faLongArrowRight,
  caretDown: faAngleDown,
  caretUp: faAngleUp,
  close: faTimes,
  chevronRight: faChevronRight,
  chevronLeft: faChevronLeft,
  loading: faSpinnerThird
};