<main role="main">
    <app-header></app-header>
    <app-hero></app-hero>
    <app-about-section></app-about-section> 
    <app-slider-component></app-slider-component>
    <app-video-slider></app-video-slider>
    <app-shutterstock-section></app-shutterstock-section>
    <app-image-grid></app-image-grid>
    <app-disclaimer-section></app-disclaimer-section>
    <app-about-dream-section></app-about-dream-section>
</main>