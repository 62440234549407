<section>
    <app-section-content>
        <div class="container">
            <h1 class="title">Making Inclusion the Standard</h1>
            <div class="sub-headline">
                <h2>In partnership with</h2>
                <img class="shutterstock-image"
                    src='https://media.allergan.com/allerganaesthetics/dreamforequity/Logos/Shutterstock_logo.svg' />
            </div>
            <div class="content">
                <p>
                    Authentic representation in aesthetics has long been lacking—until now. United by a shared desire
                    to
                    create diverse
                    content on a larger scale, the DREAM Initiative® partnered with Shutterstock Studios, Shutterstock’s
                    end-to-end custom
                    creative shop. Together, with these images, we’re ensuring inclusive representation is available to
                    the
                    public.
                </p>
                <p>
                    We cast
                    hundreds of models from all walks of life, capturing what each perceives as beautiful about
                    themselves. The
                    result?
                    Thousands of images that authentically showcase beauty across race, culture, gender, age, and body
                    type. All
                    available
                    royalty-free.
                </p>
                <p>Leverage these images to join us in closing the representation gap.</p>
            </div>
            <div class="btn-container">
                <app-btn 
                  (onClick)="openShutterstockLink('https://www.shutterstock.com/explore/dream')" 
                  [style]="buttonStyles.primary">
                  VIEW SERIES
                </app-btn>
            </div>
        </div>
    </app-section-content>
</section>