import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-aad-home',
    templateUrl: './AAD.component.html',
    styleUrls: ['./AAD.component.scss'],
})

export class AADComponent implements OnInit {
    constructor() { }
    ngOnInit(): void { }
}